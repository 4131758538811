body {
  	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #eeeeee;
	transition: all 0.5s ease-in-out;
	position: fixed;
	height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  	height: calc(var(--vh, 1vh) * 100);
	width: 100%;
}
code {
  	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
html {
  	overflow: hidden;
}

div.MuiFormControl-root.shake {
	animation: shake 0.5s ease-in-out;
  	transform: translate3d(0, 0, 0);
}
/* animations start */
@keyframes shake {
	10%, 90% {
		transform: translate3d(-1px, 0, 0);
	}

	20%, 80% {
		transform: translate3d(2px, 0, 0);
	}

	30%, 50%, 70% {
		transform: translate3d(-4px, 0, 0);
	}

	40%, 60% {
		transform: translate3d(4px, 0, 0);
	}
}
/* animations end */
.MuiFormHelperText-root {
	margin-left: 0px;
}